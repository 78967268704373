<template>
    <section>      
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title">RUN builder: {{frmData.path}}</p>
        </header>
        
        
        <section class="modal-card-body">          
          
          <b-field class="columns is-multiline">
            <div class="column is-6">
              
              <b-field label="Title">
                <b-input type="text" :value="frmData.title" v-model="frmData.title" placeholder="title"></b-input>
              </b-field>

              <b-field label="Note">
                <b-input maxlength="20000" type="textarea" :value="frmData.note" v-model="frmData.note" placeholder="note..."></b-input>
              </b-field>
              
              <b-field v-if="frmData.settingsData" label="Previous settings file">
                <b-select placeholder="Select Settings" v-model="selectedSettingsFile" @input="selectSettingsChanged">
                  <option                
                    v-for="settingFile in frmData.settingsData.settingsFiles"
                    :value="settingFile"
                    :key="`${settingFile.path}/${settingFile.name}`">
                    {{ `${settingFile.path}/${settingFile.name}` }}
                  </option>
                </b-select>
              </b-field>
              
            </div>
            
            <div class="column is-6">
              
              <b-field label="Settings">
                <b-input maxlength="2000000" type="textarea" custom-class="textareaHeightMainTemplate2" v-model="frmData.settingsContent"
                @input="validateInputJSON">
                </b-input>
              </b-field>
              
              <b-checkbox v-model="frmData.saveAsDefault">Save as default</b-checkbox>
            </div>           
          </b-field>
          
        </section>
        
        
        <footer class="modal-card-foot">
          <!-- <b-button label="Close" @click="$parent.close()" /> -->          
          <b-button label="Queue Run Job" :type="this.inputJSONisValid ? 'is-primary' : 'is-warning is-light'" @click="save" /> 
          <div class="columns is-multiline">
            <div class="column is-12">time: {{ !greaterThanZero(totalRunTime) ? '-' : Math.round(totalRunTime / 3600 * 100) / 100 }} hour</div>
            <div class="column is-12 hasError" v-for="(inputErr, key) in inputJSONErrMsg" v-bind:key="`${key}_json_errors`">
              {{ inputErr }}
            </div>
          </div>
          
        </footer>
      </div>
      
      <b-notification :closable="false">
        <b-loading :is-full-page="true" :active.sync="pageLoading"></b-loading>
      </b-notification>
    </section>
</template>

<script>

// import { mapState } from 'vuex';
import { DDMMYYYY } from '@/helpers/dates.js';
const jsonFormat = require("json-format")

import axios_api from '@/plugins/axios_api';
import { API_ROOT } from '@/config/app.js';
import { waitTime } from '@/helpers/functions.js';

export default {
    name: "runBuilderForm",
    
    components: {
    },
    
    props: {
      runBuildInfo: {
        title: '',
        note: '',
        settingsData: [],
        settingsContent: '',
        path: '',
        jsonFile: ''
      }
    },
    
    data() {
      return {
        pageLoading         : false,
        pageLoadingTimeout  : null,
        
        companyId           : null,
      
        frmData   : {
          title: '',
          note: '',
          settingsContent: '',
          saveAsDefault: true,
          path: '',
          jsonFile: ''
        },
        selectedSettingsFile: 0,
        
        inputJSONisValid: true,
        inputJSONErrMsg: [],
        totalRunTime: 0
      }
    },
    
    mounted(){
      this.companyId = this.runBuildInfo.companyId
      this.frmData = this.runBuildInfo
      this.frmData.saveAsDefault = false
      this.selectedSettingsFile = this.frmData.settingsData.settingsFiles[0]
      this.updateSettings()
    },
    
    methods: { 
      DDMMYYYY,
      greaterThanZero(val){
        if (!val) return false
        if (val <= 0) return false
        return true
      },
      selectSettingsChanged(selectedSettingsFile){
        // console.info(`new SettingsFile selected:`, selectedSettingsFile)
        this.selectedSettingsFile = selectedSettingsFile
        this.updateSettings()
      },
      
      updateSettings() {
        // console.info(`this.selectedSettingsFile:`, this.selectedSettingsFile)
        let settings = JSON.parse(this.selectedSettingsFile.content)
        this.frmData.settingsContent = jsonFormat(settings)
        this.validateInputJSON()
      },
      
      validateInputJSON() {
        // console.info(`validateInputJSON:`)
        let jsonContent = this.frmData.settingsContent
        let inputJSONisValid = true
        let inputJSONWarnings = []
        let totalRuntime = 0
        try {
          let mustHaveKeys = ['phase', 'model_objectives', 'model_kwargs', 'solver_params']
          if (!jsonContent || jsonContent.trim() === '') {
            inputJSONWarnings.push('no input json found')
            throw `no input json found`
          }
          
          let inputJSON = JSON.parse(jsonContent)
          for(let key of mustHaveKeys) {
            if (typeof(inputJSON[key]) === "undefined") {
              inputJSONWarnings.push(`json key ${key} is missing`)
              inputJSONisValid = false
            }
          }
          
          //2. check objectives
          if (inputJSON.model_objectives.constructor != Array) {
            inputJSONWarnings.push(`model_objectives missing!`)
            inputJSONisValid = false
          }
          let allowedObjectivesList = new Set([
            'PRIORITIZE_LINKED_SHIFTS',
            'STREAKS_HAVE_PRECEDENCE',
            'EQUAL_DISTRIBUTION_OF_SHIFTS',
            'FAIR_SHIFT_VALUE_DIST',
            'ROLE_PREFERENCES',
            'EVEN_DIST_SHIFTS_EMP_TIME',
            'EVEN_DIST_SHIFTS_EMP_MONTH',
            'EVEN_DIST_SHIFTS_EMP_WEEK',
            'EVEN_DIST_SHIFTS_GLOBALLY_TIME',            
            'FAIR_ASSIGNMENT_HOLIDAYS',
            'COMPL_AVAIL_FOR_PART_GR_SHIFTS_PRIO',
            'ASSIGN_TO_WORST_PERFORMERS',
            'FAIR_ASSIGNMENT_BACKUP_SHIFTS',
          ])
          let mustHaveKeysSolver = ['objectives', 'fix_constraints_after', 'run_time', 'scales']
          for(let objectiveNr in inputJSON.model_objectives) {
            let moddel_objective = inputJSON.model_objectives[objectiveNr]
            for(let key of mustHaveKeysSolver) {
              if (typeof(moddel_objective[key]) === "undefined") {
                inputJSONWarnings.push(`moddel_objective nr ${objectiveNr} is missing ${key}`)
                inputJSONisValid = false
              }
            }
            if (moddel_objective.objectives.length <= 0) {
              inputJSONWarnings.push(`objective nr ${objectiveNr} has no items`)
              inputJSONisValid = false
            } else {
              for(let objective of moddel_objective.objectives) {
                if (allowedObjectivesList.has(objective) !== true) {
                  inputJSONWarnings.push(`moddel_objective nr ${objectiveNr} is unknown objective: ${objective}`)
                  inputJSONisValid = false
                }
              }
            }
            if (moddel_objective.scales.length <= 0) {
              inputJSONWarnings.push(`moddel_objective nr ${objectiveNr} has no scales`)
              inputJSONisValid = false
            }
            if (moddel_objective.scales.length !== moddel_objective.objectives.length) {
              inputJSONWarnings.push(`moddel_objective nr ${objectiveNr} size != scales ${moddel_objective.objectives.length} vs ${moddel_objective.scales.length}`)
              inputJSONisValid = false
            }
            if (isNaN(parseInt(moddel_objective.run_time))) {
              inputJSONWarnings.push(`moddel_objective nr ${objectiveNr} runtime invalid ${moddel_objective.run_time}`)
              inputJSONisValid = false
            } else if (moddel_objective.run_time <= 0){
              inputJSONWarnings.push(`moddel_objective nr  ${objectiveNr} runtime < 0 => ${moddel_objective.run_time}`)
            } else if (moddel_objective.run_time > 4800){
              inputJSONWarnings.push(`moddel_objective nr ${objectiveNr} runtime > 4800 => ${moddel_objective.run_time}`)
            } else if (moddel_objective.run_time < 480){
              inputJSONWarnings.push(`moddel_objective nr ${objectiveNr} runtime low ${moddel_objective.run_time}`)
              inputJSONisValid = false
              totalRuntime += moddel_objective.run_time
            } else {
              totalRuntime += moddel_objective.run_time
            }
          }
          
          totalRuntime += inputJSON.solver_params.pre_run_time
        
        } catch (err) {
          console.info(`Error validateInputJSON:`, err.message); 
          inputJSONWarnings.push(err.message)
          inputJSONisValid = false          
        }
        this.inputJSONisValid = inputJSONisValid
        this.inputJSONErrMsg = inputJSONWarnings
        this.totalRunTime = totalRuntime
      },
      
      
      async ShowPageLoading(maxDuration = 30000){
        this.pageLoading = true;
        
        if (this.pageLoadingTimeout !== null) {
          clearTimeout(this.pageLoadingTimeout);
        }
        
        this.pageLoadingTimeout = setTimeout(() => {
          this.HidePageLoading();
        }, maxDuration);
      },
      
      async HidePageLoading() {
        this.pageLoading = false;
        if (this.pageLoadingTimeout !== null) {
          clearTimeout(this.pageLoadingTimeout);
        }
      },
      
      async save(){
        // console.info(`this.frmData:`, this.frmData)
        if (this.frmData.title.trim() === '') {
          return this.$buefy.dialog.alert({ title: 'Error', message: 'invalid title', type: 'is-danger' })
        }
        
        let settings = ''
        try {
          if (this.frmData.settingsContent.trim() === '') throw new Error('invalid settings')
          
          console.info(`this.frmData.settingsContent:`, this.frmData.settingsContent)
          settings = JSON.parse(this.frmData.settingsContent.trim())
          
        } catch (err) {
          if (err.message) {
            return this.$buefy.dialog.alert({ title: 'Error', message: `invalid settings JSON: ${err.message}`, type: 'is-danger' })
          }
          return this.$buefy.dialog.alert({ title: 'Error', message: `invalid settings JSON`, type: 'is-danger' })
        }
        
        this.ShowPageLoading(15*1000)
        
        const save = {
          title: this.frmData.title,
          note: this.frmData.note,
          path: this.frmData.path,
          jsonFile: this.frmData.jsonFile,
          settings: settings,
          saveAsDefault: this.frmData.saveAsDefault
        }
        
        let response = await axios_api.post(`${API_ROOT}/maximus/company/${this.companyId}/buildRUN/new`, save);
        if (response.data.result !== 1) {
          this.$buefy.dialog.alert({ title: 'Error', message: 'unable to save run', type: 'is-danger' })
          this.HidePageLoading()
          return;
        }
        
        this.ShowPageLoading(5*1000)        
        await waitTime(5*1000)        
        this.HidePageLoading()        
        this.$buefy.dialog.alert({ title: 'run build started', message: 'run will be completed soon', type: 'is-info' })        
        this.$emit('updateData')        
        this.$parent.close()
      }
            
    }
};
</script>

<style>
  .highlight{
    color: #0000cc;
    text-decoration: underline;
  }
  
  .textareaHeightMainTemplate3{
    /* background-color: red; */
    font-size: 14px;
    height: 700px;
    max-height: 700px!important;
  }

    
</style>
