<template>
  <section class="full-height">

    <div class="columns is-multiline is-centered is-gapless is-marginless">
      
      <div class="column is-10 is-offset-1 is-mobile is-narrow mt2rem">
        <div v-if="companyData" class="has-text-grey is-size-3 is-size-4-mobile wa-underlined-title">
            {{ companyData.companies_id }} - {{ companyData.companies_name }} schedules
        </div>
        
        <b-button class="button is-primary" @click="newJSON" @close="closeJSONBuild">New JSON</b-button>
      </div>
      <div class="column is-1 is-mobile is-narrow mt2rem">
        <a class="button is-primary" :href='`${WEB_ROOT}/company/maximus/runServerManager`' target="_blank">Manage Run Servers</a>
      </div>
    </div>
    
    
    
    <div class="is-mobile is-gapless is-centered is-multiline m2rem">
        <b-table
            :data="jsonBuildData"
            :bordered="true"
            :striped="true"
            :narrowed="true"
            :loading="tableIsLoading"
            :mobile-cards="true">

            <b-table-column field="path" label="path" width="150" numeric v-slot="props" centered>
                {{ props.row.path }}
                <div v-if="props.row.jsonStats !== null" class="columns is-multiline jsonDetailswrap">
                   <div class="column is-9 is-offset-3 jsonDetails">users: {{props.row.jsonStats.users}}</div>
                   <div class="column is-9 is-offset-3 jsonDetails">shifts: {{props.row.jsonStats.shifts}}</div>
                   <div class="column is-9 is-offset-3 jsonDetails">available: {{ props.row.jsonStats.availability}} - {{props.row.jsonStats.availabilityp}} </div>
                   <div class="column is-9 is-offset-3 jsonDetails">assigned: {{props.row.jsonStats.assigned}} - {{props.row.jsonStats.assignedp}}</div>
                   
                </div>
            </b-table-column>

            <b-table-column field="title" label="title" width="150" numeric v-slot="props" centered>
              <b-button :type="'is-success'"  class="smallbutton" @click="editTitle(props.row, props.row.title)">edit</b-button>
              {{ props.row.title }}              
            </b-table-column>
            
            <!-- <b-table-column field="note" label="note" width="200" numeric v-slot="props" sortable centered>
                <b-button :type="'is-success'"  class="smallbutton" @click="editNote(props.row, props.row.note)">edit</b-button>
                {{ props.row.note }}                
            </b-table-column> -->
            
            <b-table-column field="files" label="input json" width="150" numeric v-slot="props" centered>
                <div v-for="(file, index) in filterFiles(props.row.files)" v-bind:key="`index_${file}`" class="is-12 filesWrap">
                  <div @click="viewJsonFile(file, index,  props.row)">{{ file }}</div>
                  &nbsp; 
                  <a :href='`${WEB_ROOT}/company/${companyId}/maximus/json/${props.row.path}/preview/${file}`' target='_blank'>
                    preview
                  </a>
                </div>
                
                <b-button v-if="filterFiles(props.row.files)" :type="'is-success'" class="smallbutton" @click="generateWPFile(props.row)">WP-file</b-button>
                
                <!-- <b-button :type="'is-success'" class="smallbutton">download</b-button> -->
                <!-- <b-button :type="'is-success'" class="smallbutton" >view</b-button> -->
            </b-table-column>
            
            <b-table-column field="buildlog" label="buildlog" width="200" numeric v-slot="props" centered>
                <div v-for="(log, key) in props.row.buildlog" v-bind:key="`index_${key}`" class="is-12 filesWrap" @click="viewBuildLog(key, log)">
                  {{ key }}: {{ log.length }} items
                </div>                
            </b-table-column>
            
            <b-table-column field="runs" label="runs" width="400" numeric v-slot="props" centered>
                <div v-for="(run, key) in props.row.runs" v-bind:key="`index_${key}`" class="is-12">
                  <a :href='`${WEB_ROOT}/company/${companyId}/maximus/json/${props.row.path}/run/${run.path}`' 
                      target='_blank' @mouseover="showRunDetails(run, props.row)" @mouseout="hideRunDetails(props.row, props.row)">
                    {{ run.path }} ({{run.fileCount}}) - {{run.assignedStatsp}} 
                    {{ run.runConfig && run.runConfig.WP > 0 ? `- (WP${run.runConfig.WP})`: ''}}
                    {{ run.runConfig ? `- (${run.runConfig.RT} => ${!run.realRuntime ? '' : `${(run.realRuntime).toFixed(1)}h`})`: ''}}
                    
                  </a>
                  <a v-if="run.findOutputJSONFile" target='_blank' :href='`${WEB_ROOT}/company/${companyId}/maximus/json/${props.row.path}/run/${run.path}/preview/${run.findOutputJSONFile.name}`'>
                  p
                  </a>
                </div>
                
                <b-button @click="newRUN(props.row)" :type="'is-success'"  class="smallbutton">
                  new
                </b-button>
            </b-table-column>
            
            <b-table-column label="actions" width="100" numeric v-slot="props" centered>
                <b-button :type="'is-success'"  class="smallbutton" @click="archiveJson(props.row)">archive</b-button>
                <b-button :type="'is-success'"  class="smallbutton" @click="deleteJson(props.row)">delete</b-button>
            </b-table-column>

            
            <template #empty>
                <div class="has-text-centered">No records</div>
            </template>

        </b-table>
      </div>
    
    
    <b-modal
        v-model="showNewJSONBuilder"
        has-modal-card
        full-screen
        :can-cancel="true">
        <jsonBuilderForm :jsonBuildInfo="jsonBuildInfo" />
    </b-modal>
    
    <b-modal
        v-model="showNewRUNBuilder"
        has-modal-card
        full-screen
        :can-cancel="true"
        >
        <runBuilderForm :runBuildInfo="runBuildInfo" @updateData="getJSONbuildData"/>
    </b-modal>
    
    <b-modal
        v-model="showTextForm"
        has-modal-card
        full-screen
        :can-cancel="true">
        <textForm :textFormData="textFormData" />
    </b-modal>
    
    
    <b-notification :closable="false">
      <b-loading :is-full-page="true" :active.sync="pageLoading"></b-loading>
    </b-notification>
  </section>
</template>

<script>

// import { Auth }             from '@/plugins/firebase/auth';
// import { DB }               from '@/plugins/firebase/db';
// import axios_clean          from 'axios';
import axios_api from '@/plugins/axios_api';
// import { API_ROOT } from '@/config/app.js';

// import { Calc_TimeLeftMsg }         from '@/helpers/functions';
import { WEB_ROOT, API_ROOT } from '@/config/app.js';
// import { mapState } from 'vuex';

import jsonBuilderForm from '@/components/backend/general/jsonBuilderForm.vue'
import runBuilderForm from '@/components/backend/general/runBuilderForm.vue'
import textForm from '@/components/backend/general/textForm.vue'

// import { DDMMYYYY_HHii } from '@/helpers/dates.js';
const jsonFormat = require("json-format")

export default {
  name: "MaximusJson", 
  components: {
    jsonBuilderForm,
    runBuilderForm,
    textForm
  },  
         
  data() {
    return {
      pageLoading         : false,
      pageLoadingTimeout  : null,
      
      jsonBuildData       : [],
      tableIsLoading      : false,
      WEB_ROOT            : WEB_ROOT,
      API_ROOT            : API_ROOT,
      SITE_ROOT           : process.env.VUE_APP_SITE_MAIN,
      
      companyId           : null,
      companyData         : null,
      
      jsonBuildInfo       : {
        title: '',
        note: '',
        periodData: [],
        settingsData: []
      },
      showNewJSONBuilder : false,
      
      runBuildInfo       : {
        title: '',
        note: '',
        settingsData: []
      },
      showNewRUNBuilder : false,
      
      textFormData : {
        title: '',
        text: ''
      },
      showTextForm: false,
    }
  },
  
  async mounted(){
    this.companyId = this.$route.params.companyId;
    await this.getCompanyData()
    await this.getJSONbuildData()
    
  },
  
  methods: {
    async getCompanyData() {
      this.ShowPageLoading(15000)
      let response = await axios_api.get(`${API_ROOT}/schedule/company/${this.companyId}/Simple`, {});
      if (response.data.result !== 1) {
        this.$buefy.dialog.alert({ title: 'Error', message: 'unable to get company data', type: 'is-danger' })
        this.HidePageLoading()
        return;
      }
      this.companyData  = response.data.payload.companyData
      
      this.HidePageLoading()
    },
    
    async getJSONbuildData() {
      this.ShowPageLoading(15000)
      let response = await axios_api.get(`${API_ROOT}/maximus/company/${this.companyId}/buildJSON/getList`, {});
      if (response.data.result !== 1) {
        this.$buefy.dialog.alert({ title: 'Error', message: 'unable to get company json list', type: 'is-danger' })
        this.HidePageLoading()
        return;
      }
      
      this.jsonBuildData  = response.data.payload.jsonList
      
      this.HidePageLoading()
    },
    
    
    async ShowPageLoading(maxDuration = 30000){
      this.pageLoading = true;
      
      if (this.pageLoadingTimeout !== null) {
        clearTimeout(this.pageLoadingTimeout);
      }
      
      this.pageLoadingTimeout = setTimeout(() => {
        this.HidePageLoading();
      }, maxDuration);
    },
    
    async HidePageLoading() {
      this.pageLoading = false;
      if (this.pageLoadingTimeout !== null) {
        clearTimeout(this.pageLoadingTimeout);
      }
    },
    
    async newJSON(){      
      let response = await axios_api.get(`${API_ROOT}/maximus/company/${this.companyId}/buildJSON/info`, {});
      if (response.data.result !== 1) {
        this.$buefy.dialog.alert({ title: 'Error', message: 'unable to get company data', type: 'is-danger' })
        this.HidePageLoading()
        return;
      }
      
      this.jsonBuildInfo = {
        companyId: this.companyData.companies_id,
        title: `Run`, //`company_${this.companyData.companies_id} - ${this.companyData.companies_name} json build ${DDMMYYYY_HHii(new Date())}`,
        note: 'note',
        periodData: response.data.payload.periods,
        settingsData: response.data.payload.settings,
        settingsContent: '',
        roles: response.data.payload.roles,
        runPlans: response.data.payload.runPlans,
        
      }
            
      this.showNewJSONBuilder = true
    },
    
    closeJSONBuild(){
      this.showNewJSONBuilder = false
    },
    
    async newRUN(jsonObj){
      this.ShowPageLoading(15000)
      // console.info(`jsonObj:`, jsonObj)
      // console.info(`${API_ROOT}/maximus/company/${this.companyId}/buildRUN/info`)
      // console.info({path: jsonObj.path})
      let response = await axios_api.post(`${API_ROOT}/maximus/company/${this.companyId}/buildRUN/info`, {
        path: jsonObj.path
      });
      
      if (response.data.result !== 1) {
        this.$buefy.dialog.alert({ title: 'Error', message: 'unable to get run data', type: 'is-danger' })
        this.HidePageLoading()
        return;
      }
      this.HidePageLoading()
      
      this.runBuildInfo = {
        companyId: this.companyData.companies_id,
        path: response.data.payload.path,
        jsonFile: response.data.payload.jsonFile,
        title: `Run`, //`company_${this.companyData.companies_id} - ${this.companyData.companies_name} run build ${DDMMYYYY_HHii(new Date())}`,
        note: 'note',
        settingsData: response.data.payload.settings,
        settingsContent: ''
      }
      
      this.showNewRUNBuilder = true
    },
    
    async viewBuildLog(key, buildLog) {
      // console.info(`viewBuildLog buildLog:`, buildLog)
      this.textFormData.title = key
      this.textFormData.text = jsonFormat(buildLog)
      this.showTextForm = true
      
    },
    
    async generateWPFile(rowProps){
      // console.info(`rowProps:`,rowProps)
      this.ShowPageLoading(15000)
      let response = await axios_api.post(`${API_ROOT}/maximus/company/${this.companyId}/buildJSON/generateWP`, {
        jsonPath: rowProps.path
      });
      if (response.data.result !== 1) {
        this.$buefy.dialog.alert({ title: 'Error', message: `unable to generte run ${this.runPath} list`, type: 'is-danger' })
        this.HidePageLoading()
        return;
      }
      this.HidePageLoading()
    },
    
    async viewJsonFile(file, index, rowProps) {
      // console.info(`showFile file:`, file)
      // console.info(`showFile index:`, index)
      // console.info(`showFile rowProps:`, rowProps)
      
      let jsonPath = encodeURIComponent(`${rowProps.path}`)
      let fileName = encodeURIComponent(file)
      
      const url = `${API_ROOT}/maximus/company/${this.companyId}/buildJSON/download/${jsonPath}/${fileName}`
      // console.info(url)
      
      // console.info(`url:`, url)
      window.open(url,'_blank');
      return false
    },
    
    filterFiles(files, allowedExtentions = new Set(['xlsx'])){
      let rtn = []
      for(let file of files) {
        let ext = this.GetFileExtention(file.toLowerCase())
        // console.info(`ext:`, ext)
        if (allowedExtentions.has(ext) !== true) continue
        rtn.push(file)
      }
      
      return rtn
    },
    
    GetFileExtention(File, FailValue = "-"){
      if (typeof(File) !== "string" || File.indexOf(".") === -1) {
        return FailValue;
      }
      
      let ParseStr = File.toString(); //check if File contains folder paths ifso remove
      if (ParseStr.indexOf("/") !== -1) {
        let dirSplit = ParseStr.split("/");
        ParseStr = dirSplit[dirSplit.length -1]
      }
      
      const FileParts = ParseStr.split(".");
      const ext = FileParts[FileParts.length -1];
      if (ext.length > 4) {
        return FailValue
      }
      
      return ext;
    },
    
    async archiveJson(rowProps){
      this.$buefy.dialog.confirm({
        title: 'Archive json',
        message: `Are you sure you want to ARCHIVE '${rowProps.path}'`,
        trapFocus: true,
        onConfirm: () => this.archiveJsonConfirm(rowProps)
      })
    },
    async archiveJsonConfirm(rowProps){
      // console.info(`archiveJson:`, rowProps)
      this.ShowPageLoading(15000)
      let response = await axios_api.post(`${API_ROOT}/maximus/company/${this.companyId}/buildJSON/archive`, {
        jsonPath: rowProps.path
      });
      if (response.data.result !== 1) {
        this.$buefy.dialog.alert({ title: 'Error', message: `unable to archive json ${rowProps.path} list`, type: 'is-danger' })
        this.HidePageLoading()
        return;
      }
      
      this.getJSONbuildData()
    },
    
    async deleteJson(rowProps){
      this.$buefy.dialog.confirm({
        title: 'Delete json',
        message: `Are you sure you want to DELETE '${rowProps.path}'`,
        trapFocus: true,
        onConfirm: () => this.deleteJsonConfirm(rowProps)
      })
    },
    async deleteJsonConfirm(rowProps){
      // console.info(`archiveJson:`, rowProps)
      this.ShowPageLoading(15000)
      let response = await axios_api.post(`${API_ROOT}/maximus/company/${this.companyId}/buildJSON/delete`, {
        jsonPath: rowProps.path
      });
      if (response.data.result !== 1) {
        this.$buefy.dialog.alert({ title: 'Error', message: `unable to delete json ${rowProps.path} list`, type: 'is-danger' })
        this.HidePageLoading()
        return;
      }
      
      this.getJSONbuildData()
    },
    
    async editTitle(rowProps, curTitle){
      this.$buefy.dialog.prompt({
        title: 'Edit Title',
        message: `Please enter new Title`,
        inputAttrs: {
          placeholder: '',
          value: curTitle,
          maxlength: 100000
        },
        trapFocus: true,
        onConfirm: (value) => this.editTitleConfirm(rowProps, curTitle, value)
      })
    },
    
    async editTitleConfirm(rowProps, curTitle, newTitle){
      if (newTitle === '') {
        return this.$buefy.dialog.alert({ title: 'Error', message: `invalid title: ${newTitle}`, type: 'is-danger' })
      }
      
      if (curTitle === newTitle) {
        return
      }
      
      // console.info(`editTitleConfirm:`, rowProps)
      // console.info(`editTitleConfirm new title:`, newTitle)
      this.ShowPageLoading(15000)
      let response = await axios_api.post(`${API_ROOT}/maximus/company/${this.companyId}/buildJSON/editTitle`, {
        jsonPath: rowProps.path,
        newContent: newTitle
      });
      if (response.data.result !== 1) {
        this.$buefy.dialog.alert({ title: 'Error', message: `unable to edit title of json ${rowProps.path}`, type: 'is-danger' })
        this.HidePageLoading()
        return;
      }
      
      this.getJSONbuildData()
    },
    
    
    async editNote(rowProps, curNote){
      this.$buefy.dialog.prompt({
        title: 'Edit Note',
        message: `Please enter new Note`,
        inputAttrs: {
          placeholder: '',
          value: curNote,
          maxlength: 10000000
        },
        trapFocus: true,
        onConfirm: (value) => this.editNoteConfirm(rowProps, curNote, value)
      })
    },
    
    async editNoteConfirm(rowProps, curNote, newNote){
      if (newNote === '') {
        return this.$buefy.dialog.alert({ title: 'Error', message: `invalid note: ${newNote}`, type: 'is-danger' })
      }
      
      if (curNote === newNote) {
        return
      }
      
      this.ShowPageLoading(15000)
      let response = await axios_api.post(`${API_ROOT}/maximus/company/${this.companyId}/buildJSON/editNote`, {
        jsonPath: rowProps.path,
        newContent: newNote
      });
      if (response.data.result !== 1) {
        this.$buefy.dialog.alert({ title: 'Error', message: `unable to edit note of json ${rowProps.path}`, type: 'is-danger' })
        this.HidePageLoading()
        return;
      }
      
      this.getJSONbuildData()
    },
    
    // async gotoRunServerManage() {
    //   this.$router.push({ name: 'maximusRunServerManager' })
    //                 .catch(err => { console.info('router error:', err) });
                    
    //   const url = `${API_ROOT}/maximus/company/${this.companyId}/buildJSON/download/${jsonPath}/${fileName}`
    //   // console.info(url)
      
    //   // console.info(`url:`, url)
    //   window.open(url,'_blank');
    //   return false
    // },
    
    showRunDetails(item) {      
      console.info(`showRunDetails:`, item)
      
    },
    hideRunDetails(item) {
      console.info(`hideRunDetails:`, item)
    }
    
    
  },
};
</script>

<style>
    .editor {
      width: 94%;
      margin-left: 3%;
      height: 1000px;
    }
  
    .inputheight1{
      /* background-color: red; */
      font-size: 14px;
      height: 600px;
    }
  
    .inputheight2{
      /* background-color: red; */
      height: 560px;
      font-size: 11px;
    }
    
    .MyButton{
      /* background-color: red; */
      width: 96%;
      margin-left: 2%;
      margin-top: 2%;
    }
    
    textarea {
      resize: none;
    }
    
    .editor {
      width: 94%;
      margin-left: 3%;
      height: 1000px;
    }
  
    .inputheight1{
      /* background-color: red; */
      font-size: 14px;
      height: 600px;
    }
    
    .inputheight2{
      /* background-color: red; */
      font-size: 14px;
      height: 300px;
    }
    
    .inputheight3{
      /* background-color: red; */
      font-size: 14px;
      height: 50px;
    }
    
    .inputheight4{
      /* background-color: red; */
      font-size: 14px;
      height: 150px;
    }
    .spacer{
      height: 50px;
      width: 100%;
    }
    
    .example_messages p{
      margin-top: 15px;
      padding: 2%;
    }
    
    .filesWrap:hover{
      color: blue;
      cursor: pointer;
    }
    
    .smallbutton{
      padding: 2px;
      margin: 0px;
      margin-right: 10px;
      line-height: 20px;
      font-size: 12px;
      border: 1px solid #000;
      height: 22px;
    }
    .smallbutton:hover{
      color: black !important;
      border: 1px solid black;
    }
    .jsonDetailswrap{
      padding-top: 10px;
      padding-bottom: 10px;
    }
    .jsonDetails{
      text-align: left;
      font-size: 12px;
      line-height: 11px;
      padding: 5px;
      /* color: red; */
    }

</style>