<template>
    <section>      
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title">{{frmData.title}}</p>
        </header>
                
        <section class="modal-card-body">          
          
          <b-field class="columns is-multiline">
            <div class="column is-12">
              <b-field>
                <b-input maxlength="200000000" type="textarea" custom-class="textareaHeightMainTemplate2a" v-model="frmData.text"></b-input>
              </b-field>          
            </div>
          </b-field>
        </section>
      </div>
      
      <b-notification :closable="false">
        <b-loading :is-full-page="true" :active.sync="pageLoading"></b-loading>
      </b-notification>
    </section>
</template>

<script>

// import { mapState } from 'vuex';
import { DDMMYYYY } from '@/helpers/dates.js';
// const jsonFormat = require("json-format")

// import axios_api from '@/plugins/axios_api';
// import { API_ROOT } from '@/config/app.js';
// import { waitTime } from '@/helpers/functions.js';

export default {
    name: "runBuilderForm",
    
    components: {
    },
    
    props: {
      textFormData: {
        title: '',
        text: ''
      }
    },
    
    data() {
      return {
        pageLoading         : false,
        pageLoadingTimeout  : null,
        
        companyId           : null,
      
        frmData   : {
          title: '',
          text: '',
        },
      }
    },
    
    mounted(){
      this.frmData = this.textFormData
    },
    
    methods: { 
      DDMMYYYY,
      
            
    }
};
</script>

<style>
  .highlight{
    color: #0000cc;
    text-decoration: underline;
  }
  
  .textareaHeightMainTemplate2a{
    /* background-color: red; */
    font-size: 14px;
    height: 1000px;
    max-height: 700px!important;
  }

    
</style>
